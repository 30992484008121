import React from 'react';
import { Link } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Layout } from '../components/layout';
import { DividerBlock } from '../components/dividerBlock';

const NotFoundPage = () => (
  <Layout accentColor={{ hex: '#00ff00' }} bgcolor="Charcoal" wrapperClass="four-oh-four">
    <HelmetDatoCms title="404: Not Found" />
    <div className="container row">
      <div className="col-sm-7 offset-sm-2 col-xs-6 col">
        <h1 className="gothic--xlarge large-title">
          404:
          {' '}
          <br />
          {' '}
          Page Not Found
        </h1>
        <Link to="/" className="home-link">← Take me home</Link>
      </div>
      <div className="section-divider col-sm-5 col-xs-4 offset-xs-1 offset-sm-3 col">
        <DividerBlock accentColor={{ hex: '#00ff00' }} />
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
